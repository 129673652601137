import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';


const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled'
};

const routes: Routes = [
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    {
        path: 'total-report',
        loadChildren: () => import('./pages/total-report/total-report.routes').then((mod) => mod.TOTAL_REPORT_ROUTES)
    },
    {
        path: 'brands',
        loadChildren: () => import('./pages/login/logins.routes').then((mod) => mod.BRANDS_ROUTES)
    },
    {
        path: 'authorities',
        loadChildren: () => import('./pages/authorities/authorities.routes').then((mod) => mod.AUTHORITIES_ROUTES)
    },
    {
        path: 'authorities2',
        loadChildren: () => import('./pages/authorities2/authorities2.routes').then((mod) => mod.AUTHORITIES2_ROUTES)
    },
    {
        path: 'double-confirmation',
        loadChildren: () => import('./pages/double-confirmation/double-confirmation.routes').then((mod) => mod.DOUBLE_CONFIRMATION_ROUTES)
    },
    {
        path: 'online',
        loadChildren: () => import('./pages/online/online.routes').then((mod) => mod.ONLINE_ROUTES)
    },
    {
        path: 'retail',
        loadChildren: () => import('./pages/retail/retail.routes').then((mod) => mod.RETAIL_ROUTES)
    },
    {
        path: 'save-search',
        loadChildren: () => import('./pages/save-search/save-search.routes').then((mod) => mod.SAVE_SEARCH_ROUTES)
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.routes').then((mod) => mod.DASHBOARD_ROUTES)
    },
    {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.routes').then((mod) => mod.SETTINGS_ROUTES)
    },
    {
        path: 'tax-reporting',
        loadChildren: () => import('./pages/tax-reporting/tax-reporting.routes').then((mod) => mod.TAX_REPORTING_ROUTES)
    },
    {
        path: 'notification-history',
        loadChildren: () => import('./pages/notification-history/notification-history.routes').then((mod) => mod.NOTIFICATION_HISTORY_ROUTES)
    },
    {
        path: 'api-log',
        loadChildren: () => import('./pages/api-log/api-log.routes').then((mod) => mod.API_LOG_ROUTES)
    },
    {
        path: 'load-data',
        loadChildren: () => import('./pages/load-data-log/load-datai-log.routes').then((mod) => mod.LOAD_DATA_LOG_ROUTES)
    },
    {
        path: 'reports-log',
        loadChildren: () => import('./pages/reports-log/reports-log.routes').then((mod) => mod.REPORTS_LOG_ROUTES)
    },
    {
        path: 'critical-alert-history',
        loadChildren: () => import('./pages/critical-alert-history/critical-alert-history.routes').then((mod) => mod.CRITICAL_ALERT_HISTORY_ROUTES)
    },
    {
        path: 'total-report-tanzania',
        loadChildren: () => import('./pages/total-report-tanzania/total-report-tanzania.routes').then((mod) => mod.TOTAL_REPORT_TANZANIA_ROUTES)
    },
    {
        path: 'error',
        loadChildren: () => import('./pages/error/error.routes').then((mod) => mod.ERROR_ROUTES)
    },
    {
        path: 'login',
        loadComponent: () => import('./pages/auth/login/login.component').then((module) => module.LoginComponent)
    },

    { path: '**', redirectTo: '/all' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
