import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {from, Observable, switchMap} from "rxjs";
import {environment} from "../../../../environments/environment";
import * as JSZip from 'jszip';

@Injectable({
  providedIn: 'root'
})
export class OnlineService {

    constructor(private http: HttpClient) { }

    getOnlineReport(data: any): Observable<any> {
        return this.http.get<any>( environment.url + `/api/OnlineReports`, {params: {...data}});
    }

    getOnlineReport2(data: any): Observable<any> {
        return this.http.post<any>( environment.url + `/api/OnlineReports/lastReportDetails2`, data);
    }

    downloadLast(): Observable<any> {
        // @ts-ignore
        return this.http.get<any>( environment.url + `/api/OnlineReports/lastreports`, {responseType: 'arraybuffer'});
    }


    downloadLastAndExtract(): Observable<any> {
        // @ts-ignore
        return this.http.get<any>( environment.url + `/api/OnlineReports/lastreports`, {responseType: 'arraybuffer'})
            .pipe(
                switchMap((zipFileBuffer: any) => {
                    const jsZip = new JSZip();
                    return from(jsZip.loadAsync(zipFileBuffer));
                }),
                switchMap((zipFileContent: any) => {
                    console.log(zipFileContent)
                    return from<string>(zipFileContent.file('opencv.js').async('string'));
                })
            );
    }


    getColumnsForOnlineReport(): Observable<any> {
        return this.http.get<any>( environment.url + `/api/OnlineReports/reportfields`);
    }

    deleteReport(id: any): Observable<any> {
        return this.http.delete<any>( environment.url + `/api/OnlineReports/${id}`);
    }

    simulate(data: any): Observable<any> {
        return this.http.post<any>( environment.url + `/api/OnlineReports/simulation`, data);
    }

    storeUpdateReport(data: any): Observable<any> {
        return this.http.post<any>( environment.url + `/api/BlackList/blackListFilterColumns`, data);
    }

    getOnlineReportData(): Observable<any> {
        return this.http.get<any>( environment.url + `/api/BlackList/blackListFilterColumns`);
    }
}
